<template>
  <div class="Userhl_jianli">
    <p class="user_pagetitle">我的简历</p>
    <div v-if="jianlibj" style="padding: 2rem 0">
      <p class="Userhl_jianlismltitle">基本信息</p>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">我的头像</div>
        <div
          class="Userhl_info_ziliao_box_rbox Userhl_info_ziliao_box_rboximgbigbox"
          @mouseenter="imgtype_enter()"
          @mouseleave="imgtype_out()"
        >
          <img
            v-show="imgtype"
            class="Userhl_info_ziliao_box_rboxuimg"
            :src="userimg"
            alt=""
          />
          <div
            v-show="imgtypefalse"
            class="Userhl_info_ziliao_box_rboxuimg_box"
          >
            更换头像
          </div>
          <Uploadimg @uploadimg="uploadimgurl" :imgall="imgalls" />
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">真实姓名</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="realname" placeholder="请输入真实姓名"></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">性别</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-select v-model="sextype" placeholder="请选择性别">
            <el-option
              v-for="(item, index) in sex"
              :key="index"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">出生年月</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="usersr"
            type="date"
            placeholder="选择出生日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">工作年限</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            v-model="usergzstart"
            placeholder="请输入工作时间（年）"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">现居</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="userdz" placeholder="请输入居住地址"></el-input>
        </div>
      </div>
      <p class="Userhl_jianlismltitle">求职意向</p>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">求职状态</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-select v-model="userqztype" placeholder="请选择求职状态">
            <el-option
              v-for="(item, index) in qiuzhi"
              :key="index"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">期望职位</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            v-model="user_qwzhiwei"
            placeholder="请输入期望职位"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">期望地址</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input v-model="user_qwdz" placeholder="请输入期望地址"></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">期望薪资</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            v-model="user_qwmoney"
            placeholder="请输入期望薪资"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">海外经历</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-select v-model="user_hw" placeholder="请选择有无海外经历">
            <el-option label="有" value="1"></el-option>
            <el-option label="无" value="0"></el-option>
          </el-select>
        </div>
      </div>
      <p class="Userhl_jianlismltitle">工作经历</p>
      <div v-for="(item, index) in gongzuoall" :key="index">
        <div class="xiangmutopbox">
          <h2>
            {{
              item.gsname == "" ? "公司 " + (index + 1) + " 名称" : item.gsname
            }}
          </h2>
          <el-button
            v-if="index == 0 ? false : true"
            @click="removegs(index)"
            icon="el-icon-delete"
            plain
          ></el-button>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">公司名称</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              v-model="item.gsname"
              placeholder="请输入公司名称"
            ></el-input>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">所属行业</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              v-model="item.gstype"
              placeholder="请输入所属行业"
            ></el-input>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">职位名称</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              v-model="item.gszhiwei"
              placeholder="请输入职位名称"
            ></el-input>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">在职时间</div>
          <div
            class="Userhl_info_ziliao_box_rbox"
            style="display: flex; justify-content: space-between"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.gs_start"
              type="date"
              placeholder="请选择开始时间"
              style="margin-right: 2%"
            >
            </el-date-picker>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.gs_end"
              type="date"
              placeholder="请选择结束时间"
              style="margin-left: 2%"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">税前月薪</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              v-model="item.money"
              placeholder="请输入税前月薪"
            ></el-input>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">工作描述</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              type="textarea"
              v-model="item.gstext"
              placeholder="请输入工作描述"
            ></el-input>
          </div>
        </div>
      </div>
      <el-button plain @click="addgs" style="margin: 0 auto; display: block"
        >添加工作经验</el-button
      >
      <p class="Userhl_jianlismltitle">项目经历</p>
      <div v-for="(item, index) in xiangmuall" :key="index">
        <div class="xiangmutopbox">
          <h2>
            {{
              item.xiangmuname == ""
                ? "项目 " + (index + 1) + " 名称"
                : item.xiangmuname
            }}
          </h2>
          <el-button
            v-if="index == 0 ? false : true"
            icon="el-icon-delete"
            @click="removexiangmu(index)"
            plain
          ></el-button>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">项目名称</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              v-model="item.xiangmuname"
              placeholder="请输入项目名称"
            ></el-input>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">所属行业</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              v-model="item.xiangmuhy"
              placeholder="请输入所属行业"
            ></el-input>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">项目时间</div>
          <div
            class="Userhl_info_ziliao_box_rbox"
            style="display: flex; justify-content: space-between"
          >
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.xiangmu_start"
              type="date"
              placeholder="请选择开始时间"
              style="margin-right: 2%"
            >
            </el-date-picker>
            <el-date-picker
              value-format="yyyy-MM-dd"
              v-model="item.xiangmu_end"
              type="date"
              placeholder="请选择结束时间"
              style="margin-left: 2%"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="Userhl_info_ziliao_box">
          <div class="Userhl_info_ziliao_box_ltext">项目简介</div>
          <div class="Userhl_info_ziliao_box_rbox">
            <el-input
              type="textarea"
              v-model="item.xiangmutext"
              placeholder="请输入项目简介"
            ></el-input>
          </div>
        </div>
      </div>
      <el-button
        plain
        @click="addxiangmu"
        style="margin: 0 auto; display: block"
        >添加项目经验</el-button
      >
      <p class="Userhl_jianlismltitle">教育经历</p>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">请输入学校名称</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            v-model="school"
            placeholder="请输入请输入学校名称"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">就读时间</div>
        <div
          class="Userhl_info_ziliao_box_rbox"
          style="display: flex; justify-content: space-between"
        >
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="user_jystart"
            type="date"
            placeholder="请选择开始时间"
            style="margin-right: 2%"
            :picker-options="pickerOptionsStart"
          >
          </el-date-picker>
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="user_jyend"
            type="date"
            placeholder="请选择结束时间"
            style="margin-left: 2%"
            :picker-options="pickerOptionsEnd"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">最高学历</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-select v-model="user_jyxueli" placeholder="请选择是否统招">
            <el-option
              v-for="(item, index) in user_jyxueliall"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">所学专业</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            v-model="user_jyzhuanye"
            placeholder="请输入所学专业"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">是否统招</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-select v-model="user_jytz" placeholder="请选择是否统招">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="2"></el-option>
          </el-select>
        </div>
      </div>
      <p class="Userhl_jianlismltitle">自我评价</p>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">自我评价</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <el-input
            type="textarea"
            v-model="usertextall"
            placeholder="请输入自我评价"
          ></el-input>
        </div>
      </div>
      <div class="Userhl_info_ziliao_box">
        <div class="Userhl_info_ziliao_box_ltext">上传附件</div>
        <div class="Userhl_info_ziliao_box_rbox">
          <div v-if="uploadziptype">
            <Uploadzip @uploadzip="uploadzipurl" />
          </div>
          <div v-if="uploadziptype_f">
            <el-button disabled>上传完成！</el-button>
            <el-button plain @click="uploadzipurl_again"
              >重新上传附件</el-button
            >
          </div>
        </div>
      </div>
      <div class="addjianlibtn">
        <el-button
          v-if="jlbj_gbtype"
          type="primary"
          plain
          @click="jlck_tboxbjbtnfalse"
          >取消修改</el-button
        >
        <el-button type="primary" plain @click="addjianli">保存简历</el-button>
      </div>
    </div>
    <div v-if="jianlick">
      <div class="jlck_tbox">
        <div class="jlck_tbox_l">
          <img :src="jlbox.userpic" alt="" />
        </div>
        <div class="jlck_tbox_r">
          <div class="jlck_tbox_rt">
            <div>{{ jlbox.realname }}</div>
            <div>{{ jlbox.age }}岁</div>
            <div>{{ jlbox.sex }}</div>
          </div>
          <p class="jlck_tbox_rc">
            <span>现居：{{ jlbox.address }}</span>
            <span>毕业院校：{{ jlbox.school }}</span>
            <span>设计专业：{{ jlbox.major }}</span>
          </p>
          <p class="jlck_tbox_rc">
            <span>工作年限：{{ jlbox.uhworkstime }}</span>
            <span>职位名称：{{ jlbox.uposition }}</span>
          </p>
        </div>
        <el-button
          class="jlck_tboxbjbtn"
          icon="el-icon-edit"
          type="info"
          plain
          @click="jlck_tboxbjbtn"
          >编辑</el-button
        >
      </div>
      <div class="jlck_centerbox">
        <p class="jlck_centerboxtitle">求职意向</p>
        <ul class="jlck_centerbox_qzul">
          <li>求职状态：{{ jlbox.jobwanted }}</li>
          <li>期望职位：{{ jlbox.uposition }}</li>
          <li>期望地址：{{ jlbox.worksplace }}</li>
          <li>期望薪资：{{ jlbox.workmoney }}</li>
          <li>海外经历：{{ jlbox.overseas }}</li>
          <li class="jlck_centerbox_qzul_jn">
            <div>技能标签：</div>
            <ul>
              <li v-for="(item, index) in skillbq" :key="index">
                <el-popover
                  placement="top-start"
                  title="提示"
                  width="200"
                  trigger="hover"
                  content="技能标签请至完善资料修改"
                >
                  <div slot="reference">{{ item }}</div>
                </el-popover>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="jlck_centerbox">
        <p class="jlck_centerboxtitle">工作经历</p>
        <ul
          class="jlck_centerbox_qzul jlck_centerbox_qzulss"
          v-for="(item, index) in jlbox.expwork"
          :key="index"
        >
          <li>公司名称：{{ item.gsname }}</li>
          <li>实习时间：{{ item.gs_start }} - {{ item.gs_end }}</li>
          <li>所属行业：{{ item.gstype }}</li>
          <li>职位名称：{{ item.gszhiwei }}</li>
          <li>税前薪资：{{ item.money }}</li>
          <li class="jlck_centerbox_qzullitext">
            <span>工作描述：</span>
            <span>{{ item.gstext }}</span>
          </li>
        </ul>
      </div>
      <div class="jlck_centerbox">
        <p class="jlck_centerboxtitle">项目经历</p>
        <ul
          class="jlck_centerbox_qzul jlck_centerbox_qzulss"
          v-for="(item, index) in jlbox.userprojectall"
          :key="index"
        >
          <li>项目名称：{{ item.xiangmuname }}</li>
          <li>项目时间：{{ item.xiangmu_start }} - {{ item.xiangmu_end }}</li>
          <li>所属行业：{{ item.xiangmuhy }}</li>
          <li class="jlck_centerbox_qzullitext">
            <span>项目简介：</span>
            <span>{{ item.xiangmutext }}</span>
          </li>
        </ul>
      </div>
      <div class="jlck_centerbox">
        <p class="jlck_centerboxtitle">教育经历</p>
        <ul class="jlck_centerbox_jybox">
          <li>{{ jlbox.school }}</li>
          <li>{{ jlbox.schooltime_start }} / {{ jlbox.schooltime_end }}</li>
          <li>{{ jlbox.education }}</li>
          <li>{{ jlbox.major }}</li>
          <li>统招：{{ jlbox.recruit }}</li>
        </ul>
      </div>
      <div class="jlck_centerbox" style="border: none">
        <p class="jlck_centerboxtitle">自我评价</p>
        <div class="jlck_centerbox_hjall">
          {{ jlbox.sertextall }}
        </div>
        <p class="jlck_centerbox_hjt" style="margin: 1rem 0">
          附件：<el-button v-if="jlbox.worksadd == '' ? false : true" disabled
            >已上传</el-button
          >
          <el-button v-if="jlbox.worksadd == '' ? true : false" disabled
            >未上传</el-button
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Uploadzip from "../Uploadzip";
import Uploadimg from "@/components/Uploadimg.vue";
export default {
  components: {
    Uploadzip,
    Uploadimg,
  },
  data() {
    return {
      jlbox: {},
      imgalls: {
        name: "",
        width: "200",
        height: "200",
      },
      ////////////
      usersr: "", //出生年月
      usergzstart: "", //工作年限
      userdz: "", //现居
      userqztype: "", //求职状态
      user_qwzhiwei: "", //期望职位
      user_qwdz: "", //期望地址
      user_qwmoney: "", //期望薪资
      user_hw: "", //海外经历
      user_jystart: "", //教育经历开始时间
      user_jyend: "", //教育经历结束时间
      user_jyxueli: "", //最高学历
      user_jyzhuanye: "", //所学专业
      user_jytz: "", //是否统招
      usertextall: "", //自我评价
      uploadzipurls: "", //附件
      userimg: "https://task.hozoin.cn/Works/20201104/sj-4369072618.png", //头像
      //完善资料的数据更改
      realname: "", //真是姓名
      school: "", //毕业院校
      sextype: "", //性别

      //////////////////////
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.user_jyend) {
            return time.getTime() > new Date(this.user_jyend).getTime();
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.user_jystart) {
            return (
              time.getTime() < new Date(this.user_jystart).getTime() - 86400000
            );
          }
        },
      },
      user_jyxueliall: [
        {
          name: "专科",
          id: "3",
        },
        {
          name: "本科",
          id: "4",
        },
        {
          name: "硕士",
          id: "5",
        },
        {
          name: "MBA",
          id: "6",
        },
        {
          name: "EMBA",
          id: "7",
        },
        {
          name: "博士",
          id: "8",
        },
        {
          name: "其他",
          id: "9",
        },
      ],
      imgtypefalse: false,
      imgtype: true,
      uploadziptype: true,
      uploadziptype_f: false,
      jianlibj: false,
      jianlick: false,
      value1: "",
      sex: [
        {
          value: "男",
        },
        {
          value: "女",
        },
      ],
      qiuzhi: [
        {
          value: "求职中",
          id: "1",
        },
        {
          value: "在职已提交离职手续",
          id: "2",
        },
        {
          value: "在职未提交离职手续",
          id: "3",
        },
      ],
      xiangmuall: [
        {
          xiangmuname: "",
          xiangmuhy: "",
          xiangmu_start: "",
          xiangmu_end: "",
          xiangmutext: "",
        },
      ],
      gongzuoall: [
        {
          gsname: "",
          gstype: "",
          gszhiwei: "",
          gs_start: "",
          gs_end: "",
          money: "",
          gstext: "",
        },
      ],
      skillbq: [],
      user_info_iostanbqall: [
        {
          text: "规划设计",
          num: "1",
          classred: "",
          ft: "0",
        },
        {
          text: "景观设计",
          num: "2",
          classred: "",
          ft: "0",
        },
        {
          text: "建筑设计",
          num: "3",
          classred: "",
          ft: "0",
        },
        {
          text: "室内设计",
          num: "4",
          classred: "",
          ft: "0",
        },
        {
          text: "软装设计",
          num: "5",
          classred: "",
          ft: "0",
        },
        {
          text: "服装设计",
          num: "6",
          classred: "",
          ft: "0",
        },
        {
          text: "家具设计",
          num: "7",
          classred: "",
          ft: "0",
        },
        {
          text: "交通工具设计",
          num: "8",
          classred: "",
          ft: "0",
        },
        {
          text: "轻工产品设计",
          num: "9",
          classred: "",
          ft: "0",
        },
        {
          text: "机械设备设计",
          num: "10",
          classred: "",
          ft: "0",
        },
        {
          text: "广告设计",
          num: "11",
          classred: "",
          ft: "0",
        },
        {
          text: "包装设计",
          num: "12",
          classred: "",
          ft: "0",
        },
        {
          text: "图书设计",
          num: "13",
          classred: "",
          ft: "0",
        },
        {
          text: "展陈设计",
          num: "14",
          classred: "",
          ft: "0",
        },
        {
          text: "品牌标志设计",
          num: "15",
          classred: "",
          ft: "0",
        },
      ],
      jlbj_gbtype: false,
      userinfo: {},
    };
  },
  methods: {
    addxiangmu() {
      var that = this;
      var xiangmulist = {
        xiangmuname: "",
        xiangmuhy: "",
        xiangmu_start: "",
        xiangmu_end: "",
        xiangmutext: "",
      };
      that.xiangmuall.push(xiangmulist);
    },
    removexiangmu(id) {
      var that = this;
      that.xiangmuall.splice(id, 1);
    },
    addgs() {
      var that = this;
      var gslist = {
        gsname: "",
        gstype: "",
        gszhiwei: "",
        gs_start: "",
        gs_end: "",
        money: "",
        gstext: "",
      };
      that.gongzuoall.push(gslist);
    },
    removegs(id) {
      var that = this;
      that.gongzuoall.splice(id, 1);
    },
    uploadzipurl(url) {
      var that = this;
      that.uploadzipurls = url;
    },
    uploadzipurl_again() {
      this.uploadzipurls = "";
    },
    imgtype_enter() {
      var that = this;
      that.imgtypefalse = true;
    },
    imgtype_out() {
      var that = this;
      that.imgtypefalse = false;
    },
    uploadimgurl(rul) {
      this.userimg = rul;
    },
    addjianli() {
      var that = this;
      console.log(that.user_jytz);
      if (that.sextype == "女") {
        that.sextype = "1";
      } else {
        that.sextype = "2";
      }
      if (that.userqztype == "求职中") {
        that.userqztype = "1";
      } else if (that.userqztype == "在职已提交离职手续") {
        that.userqztype = "2";
      } else if (that.jlbox.jobwanted == "在职未提交离职手续") {
        that.userqztype = "3";
      }
      if (that.user_hw == "无") {
        that.user_hw = "0";
      } else {
        that.user_hw = "1";
      }
      if (that.user_jytz == "是" || that.user_jytz == "1") {
        that.user_jytz = "1";
      } else {
        that.user_jytz = "2";
      }
      if (that.user_jyxueli == "大专") {
        that.user_jyxueli = "3";
      } else if (that.user_jyxueli == "本科") {
        that.user_jyxueli = "4";
      } else if (that.user_jyxueli == "硕士") {
        that.user_jyxueli = "5";
      } else if (that.user_jyxueli == "MBA") {
        that.user_jyxueli = "6";
      } else if (that.user_jyxueli == "EMBA") {
        that.user_jyxueli = "7";
      } else if (that.user_jyxueli == "博士") {
        that.user_jyxueli = "8";
      } else if (that.user_jyxueli == "其他") {
        that.user_jyxueli = "9";
      }
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/upUserResume",
        method: "post",
        data: {
          userpic: that.userimg,
          address: that.userdz,
          birthday: that.usersr,
          schooltime_start: that.user_jystart,
          schooltime_end: that.user_jyend,
          major: that.user_jyzhuanye,
          sertextall: that.usertextall,
          worksadd: that.uploadzipurls,
          expwork: that.gongzuoall,
          uposition: that.user_qwzhiwei,
          jobwanted: that.userqztype,
          uhworkstime: that.usergzstart,
          worksplace: that.user_qwdz,
          workmoney: that.user_qwmoney,
          overseas: that.user_hw,
          recruit: that.user_jytz,
          education: that.user_jyxueli,
          userprojectall: that.xiangmuall,
          realname: that.realname,
          school: that.school,
          sex: that.sextype,
          winning: [],
          works: [],
        },
      }).then((res) => {
        if (res.data.code == 1) {
          console.log(res.data);
          that.$message("简历修改完成");
          setTimeout(() => {
            location.reload();
          }, 1000);
        } else {
          that.$message(res.data.messages);
        }
      });
    },
    jlck_tboxbjbtn() {
      var that = this;
      that.jianlibj = true;
      that.jianlick = false;
    },
    jlck_tboxbjbtnfalse() {
      var that = this;
      that.jianlibj = false;
      that.jianlick = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    hjadd(id) {
      var hjaddtext = { text: "" };
      if (id == 1) {
        this.jiang.push(hjaddtext);
      } else {
        this.zuopin.push(hjaddtext);
      }
    },
  },
  created() {
    var that = this;
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
    this.tokentimeyz();
    this.userinfo = userinfo;
    this.$axios({
      headers: { "X-CSRF-TOKEN": that.userinfo.token },
      url: "/api/getResumeInfo",
      method: "post",
      data: {
        userid: userinfo.userid,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.jlbox = res.data.result;
        ///////////////查看处理
        if (that.jlbox.sex == "1") {
          that.jlbox.sex = "女";
        } else {
          that.jlbox.sex = "男";
        }
        if (that.jlbox.jobwanted == "1") {
          that.jlbox.jobwanted = "求职中";
        } else if (that.jlbox.jobwanted == "2") {
          that.jlbox.jobwanted = "在职已提交离职手续";
        } else if (that.jlbox.jobwanted == "3") {
          that.jlbox.jobwanted = "在职未提交离职手续";
        }
        if (that.jlbox.overseas == "0") {
          that.jlbox.overseas = "无";
        } else {
          that.jlbox.overseas = "有";
        }
        if (that.jlbox.recruit == "1") {
          that.jlbox.recruit = "是";
        } else {
          that.jlbox.recruit = "否";
        }
        if (that.jlbox.education == "3") {
          that.jlbox.education = "大专";
        } else if (that.jlbox.education == "4") {
          that.jlbox.education = "本科";
        } else if (that.jlbox.education == "5") {
          that.jlbox.education = "硕士";
        } else if (that.jlbox.education == "6") {
          that.jlbox.education = "MBA";
        } else if (that.jlbox.education == "7") {
          that.jlbox.education = "EMBA";
        } else if (that.jlbox.education == "8") {
          that.jlbox.education = "博士";
        } else if (that.jlbox.education == "9") {
          that.jlbox.education = "其他";
        }
        var skillbq = that.jlbox.skill.split(",");

        for (var s = 0; s < that.user_info_iostanbqall.length; s++) {
          for (var i = 0; i < skillbq.length; i++) {
            if (that.user_info_iostanbqall[s].num == skillbq[i]) {
              skillbq[i] = that.user_info_iostanbqall[s].text;
            }
          }
        }
        that.skillbq = skillbq;
        ///////////////查看处理
        ///////编辑处理
        that.usersr = that.jlbox.birthday;
        that.usergzstart = that.jlbox.uhworkstime;
        that.userdz = that.jlbox.address;
        that.userqztype = that.jlbox.jobwanted;
        that.user_qwzhiwei = that.jlbox.uposition;
        that.user_qwdz = that.jlbox.worksplace;
        that.user_qwmoney = that.jlbox.workmoney;
        that.user_hw = that.jlbox.overseas;
        that.user_jystart = that.jlbox.schooltime_start;
        that.user_jyend = that.jlbox.schooltime_end;
        that.user_jyxueli = that.jlbox.education;
        that.user_jyzhuanye = that.jlbox.major;
        that.user_jytz = that.jlbox.recruit;
        that.usertextall = that.jlbox.sertextall;
        that.uploadzipurls = that.jlbox.worksadd;
        that.userimg = that.jlbox.userpic;
        // 工作经历
        if (that.jlbox.expwork == null || that.jlbox.expwork == "") {
          that.gongzuoall;
        } else {
          that.gongzuoall = that.jlbox.expwork;
        }
        // 工作经历
        //项目经历
        if (
          that.jlbox.userprojectall == null ||
          that.jlbox.userprojectall == ""
        ) {
          that.xiangmuall;
        } else {
          that.xiangmuall = that.jlbox.userprojectall;
        }
        //项目经历
        that.realname = that.jlbox.realname;
        that.school = that.jlbox.school;
        that.sextype = that.jlbox.sex;
        console.log(that.jlbox.winning);

        ///////编辑处理
        if (that.jlbox.education != "") {
          that.jianlick = true;
          that.jlbj_gbtype = true;
        } else {
          that.jianlibj = true;
        }
      } else {
        that.jianlibj = true;
      }
    });
  },
  watch: {
    uploadzipurls: function () {
      if (this.uploadzipurls != "") {
        this.uploadziptype = false;
        this.uploadziptype_f = true;
      } else {
        this.uploadziptype = true;
        this.uploadziptype_f = false;
      }
    },
  },
};
</script>
